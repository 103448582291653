/*! _landing-v2.scss | Maulik Patel | DevOps Engineer */

/* ==========================================================================
Landing kit v3 SCSS file
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Hero styles
1. Floating chat bubbles
2. Section title
3. Icon box
4. Font
5. Images
6. testimonials
7. Wavy footer
8. Pricing page
10. Tablet media queries (portrait orientation)
11. Mac Float
=============================================================================
***/

/* ==========================================================================
0. Hero styles
========================================================================== */

//Hero header with wave shape
.hero.hero-wavy:before {
    background: transparent url(../images/illustrations/wavy-alt.png) no-repeat scroll 0 bottom / 100% auto;
    content: "";
    height: 321px;
    width: 100%;
    z-index: 1 !important;
    position: absolute;
    bottom: -1px;
    left: 0;
}

//Page title 
.hero-caption {
    text-align: left;
    .title.big-landing-title {
        color: $white;
        z-index: 999;
        font-size: 2.8rem;
        font-family: 'Nexa Bold', sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        padding: 10px 0;
    }
    .subtitle {
        margin-bottom: 0;
    }
}

//Page title adjustment on mobile
@media (max-width: 767px) {
    .hero-caption {
        text-align: center !important;
        h1, h2 {
            padding-right: 0;
        }
    }
}

//Hero canvas icons
#canvas {
    margin: 0;
    padding: 0;
    width: 100%;
    overflow: visible;
    position: absolute;
    top: 10%;
}

//Floating avatars on homepage hero
.floating-avatars .avatar {
    height: 60px;
    border-radius: 100px;
    position: absolute;
    &.avatar-1st {
        left: 50%;
        top: 64%;
    }
    &.avatar-2nd {
        height: 40px;
        left: 80%;
        top: 45%;
    }
    &.avatar-3rd {
        height: 50px;
        left: 68%;
        top: 60%;
    }
    &.avatar-4th {
        left: 90%;
        top: 75%;
    }
    &.avatar-5th {
        height: 50px;
        left: 33%;
        top: 55%;
    }
    &.avatar-6th {
        left: 20%;
        top: 69%;
        z-index: 2;
    }
    &.avatar-7th {
        height: 40px;
        left: 8%;
        top: 45%;
    }
}

//pricing hero
.pricing-hero {
    padding: 120px 0;
    min-height: 70vh;
}

/* ==========================================================================
1. Floating chat bubbles
========================================================================== */

//Floating chat bubbles on homepage
.floating-bubbles {
    .bubble  {
        img {
            height: 100px;
            position: absolute;
            &.bubble-1st {
                left: 20%;
                top: -5%;  
                animation-delay: .5s;
            }
            &.bubble-2nd {
                left: 50%;
                top: 25%;    
                animation-delay: 1.5s;
            }
            &.bubble-3rd {
                left: 15%;
                top: 75%;    
                animation-delay: 2.5s;
            }
            &.bubble-4th {
                right: 15%;
                top: -15%;    
                animation-delay: 4.5s;
            }
            &.bubble-5th {
                right: 15%;
                top: -15%;    
                animation-delay: 3.5s;
            }
            &.bubble-6th {
                right: 50%;
                top: 20%;    
                animation-delay: 2s;
            }
            &.bubble-7th {
                right: 10%;
                top: -80%;    
                animation-delay: 1.7s;
                transform: rotate(-60deg);
            }
            &.bubble-8th {
                left: 10%;
                top: -7%;    
                animation-delay: 5.3s;
            }
            &.bubble-9th {
                left: 30%;
                top: 80%;    
                animation-delay: 5.3s;
            }
        }
    }
}

/* ==========================================================================
2. Section title
========================================================================== */

.subtitle:not(:last-child), .title:not(:last-child), .content h5 {
    margin-bottom: 0 !important;
}

.section-title-wrapper {
    .section-title-landing {
        font-family: 'Nexa Bold', sans-serif;
        font-size: 2.7rem;
        color: $blue-grey;
        padding: 20px 0;
    }
    h4 {
        font-family: 'Nexa Light', sans-serif;
        font-weight: 300;
        font-size: 1.2rem;
        color: $muted-grey;
    }
}

.title-wrapper {
    .top-subtitle {
        text-transform: uppercase;
        color: $muted-grey;
        font-family: 'Nexa Light', sans-serif;
        font-weight: 700;
        font-size: 1.05rem;
    }
    h2 {
        font-size: 2rem !important;
    }
}

.quick-feature {
    font-family: 'Nexa Bold', sans-serif;
    font-size: 2rem;
    font-weight: 700;
}

.title {
    &.is-landing {
        font-family: 'Nexa Bold', sans-serif;
    }
    &.is-landing-light {
        font-family: 'Nexa Light', sans-serif;
    }
}

.side-card {
    width: 100%;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid darken($fade-grey, 1%);
    background: $white;
    display: flex;
    align-items: center;
    box-shadow: $light-box-shadow;
    margin-bottom: 20px;
    transition: all .3s;

    &:last-child {
        margin-bottom: 0;
    }

    &:hover {
        transform: translateY(-5px);

        .round-icon {
            background: $sidebar !important;
        }
    }

    .round-icon {
        height: 60px;
        width: 60px;
        min-width: 60px;
        border-radius: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all .3s;

        &.is-primary {
            background: $primary;
        }

        &.is-accent {
            background: $accent;
        }

        svg {
            height: 28px;
            width: 28px;
            stroke: $smoke-white;
            stroke-width: 1px;
        }
    }

    .meta {
        margin-left: 16px;

        span, a {
            display: block;

            &:first-child {
                font-weight: 500;
                color: $dark-text;
            }

            &:nth-child(2) {
                color: $muted-grey;
                font-size: .9rem;
            }

            &:nth-child(3) {
                color: $primary;
                font-size: .95rem;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}


//Vertical process
.vertical-process {
    .vertical-block {
        position: relative;
        display: flex;
        align-items: start;
        margin-bottom: 40px;
        &:before {
            position: absolute;
            left: -24px;
            bottom: 3px;
            content: '';
            height: 1px;
            width: 88px;
            background: $placeholder;
            transform: rotate(90deg) !important;
        }
        &:last-child {
            &:before {
                display: none;
            }
        }
        &:hover {
            .process-icon {
                background: $secondary;
                border-color: $secondary;
                i {
                    color: $smoke-white;
                }
            }
            .vertical-text {
                h4 {
                    color: $secondary;
                }
                h3 {
                    color: $primary;
                }
            }
        }
        .process-icon {
            position: relative;
            z-index: 2;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
            width: 40px;
            min-width: 40px;
            border-radius: 50%;
            border: 1px solid $placeholder;
            transition: all .3s;
            i {
                color: $secondary;
                font-size: 22px;
                font-weight: 600;
                transition: all .3s;
            }
        }
        .vertical-text {
            margin: 0 20px;
            h4 {
                font-family: 'Nexa Bold', sans-serif;
                font-size: 1.4rem;
                font-weight: 500;
                margin-bottom: 6px;
                transition: all .3s;
            }
            h3 {
                font-family: 'Nexa Bold', sans-serif;
                font-size: 1rem;
                font-weight: 500;
                margin-bottom: 6px;
                transition: all .3s;
            }
            p {
                font-size: .95rem;
                color: $muted-grey;
            }
        }
    }
}

/* ==========================================================================
3. Icon box
========================================================================== */

.chat-icon-box {
    i {
        font-size: 3.8rem;
        font-weight: normal;
        color: $primary;
    }
    .box-title {
        font-family: 'Nexa Light', sans-serif;
        padding: 5px 0;
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        color: $primary;
    }
}

.square-icon-box {
    .box-title {
        font-family: 'Nexa Light';
        font-size: 1.1rem;
        font-weight: 700;
    }
    .box-text {
        font-size: 14px;
    }
}

/* ==========================================================================
4. Font
========================================================================== */

//Font styling helper class
.is-landing {
    font-family: 'Nexa Light', sans-serif !important;
    font-weight: 700;
}

/* ==========================================================================
5. Images
========================================================================== */

//animated UI svg
.vivus-svg {
    max-height: 450px;
    width: 450px;
}

//Featured images settings
.featured-svg {
    max-width: 450px;
    &.is-large {
        max-width: 540px;
    }
}


/* ==========================================================================
6. testimonials
========================================================================== */

//Testimonials company logo position
.flat-testimonial-item {
    .company {
        max-width: 100px;
        margin-right: 0 !important;
    }
}

/* ==========================================================================
7. Wavy footer
========================================================================== */
.section.footer-wavy:before {
    background: transparent url(../images/illustrations/wavy-alt.png) no-repeat scroll 0 bottom / 100% auto;
    content: "";
    height: 321px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1 !important;
}

//Footer logo adjustment on mobile
@media (max-width: 768px) {
    .footer-logo {
        padding-top: 20px;
    }
}

/* ==========================================================================
8. Pricing page
========================================================================== */

//Pricing table position
#fancy-pricing {
    margin-top: -20%;
}

//Material inputs color adjustment
.contact-material {
    .material-input {
        color: $white !important;
    }
}

//Giant newsletter subscribe input
.giant-input-wrapper {
    padding: 30px 40px;
    background: $section-grey-accent;
    .giant-input {
        position: relative;
        min-width: 300px;
        max-width: 100%;
        input {
            padding: 20px 165px 20px 20px;
            width: 100%;
            background: $white;
            color: $muted-grey;
            border: none;
            height: 60px;
            line-height: 60px;
            transition: all 0.5s;
            border: 1px solid rgba(213,220,247,0.59);
            &:focus {
                outline: none !important;
                box-shadow:  0px 5px 43px rgba(0, 0, 0, 0.18) !important;
            }
        }
        button.inner {
            position: absolute;
            right: 7px;
            top: 7px;
        }
    }
}

/* ==========================================================================
9. Login and Sign up pages
========================================================================== */

//Login and signup cards
.clean-login-card, .clean-signup-card {
    padding: 40px;
    border-radius: 6px;
    max-width: 400px;
    margin: auto;
    background-color: $white;
    border: 1px solid #ecf0f1;
    box-shadow: 0 1px 5px rgba(0,0,0,0.05);
    h2 {
        font-size: 24px;
        padding: 20px 0;
        font-weight: 700 !important;
        font-family: 'Nexa Light', sans-serif;
    }
}

.signup-context {
    border: none !important;
    border-radius: 6px;
    .argument {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
        padding-top: 30px;
        .icon i {
            font-size: 2.4rem;
            color: $white;
        }
        .argument-text {
            margin-left: 22px;
            color: $white;
        }
    }
}

/* ==========================================================================
10. Tablet media queries (portrait orientation)
========================================================================== */

//Tablet portrait media queries
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) { 
    .is-tablet-padded {
        padding: 0 10%;
    }
    .is-tablet-padded-lg {
        padding: 0 20%;
    }

}

//To display technologies
@media (max-width: 767px) {
    .pricing-card {
        padding: 24px !important;
    }

    .partner-logos {
        img {
            height: 54px !important;
            //margin: 0 12px !important;
            margin: auto;
        }
    }
}


/* ==========================================================================
11. Mac Float
========================================================================== */

.a {
    width: 100%;
    height: 60vh;
    //background: #169fd4;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .avatar {
    width: 200px;
    margin: 0 auto;
    transition:.2s;
  }
  #sobrancelhas {
    -webkit-transform: translateY(-9px);
            transform: translateY(-9px);
    -webkit-animation: sobrancelhas 5s linear infinite;
            animation: sobrancelhas 5s linear infinite;
  }
  
  @-webkit-keyframes sobrancelhas {
    0% {
      -webkit-transform: translateY(-10);
              transform: translateY(-10);
      -ms-transform: translateY(-10);
      -moz-transform: translateY(-10);
    }
  
    25% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -ms-transform: translateY(0px);
      -moz-transform: translateY(0px);
    }
  
    75% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -moz-transform: translateY(0px);
      -ms-transform: translateY(0px);
    }
  
    100% {
      -webkit-transform: translateY(10);
              transform: translateY(10);
      -moz-transform: translateY(10);
      -ms-transform: translateY(10);
    }
  }
  
  @keyframes sobrancelhas {
    0% {
      -webkit-transform: translateY(-10);
              transform: translateY(-10);
      -ms-transform: translateY(-10);
      -moz-transform: translateY(-10);
    }
  
    25% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -ms-transform: translateY(0px);
      -moz-transform: translateY(0px);
    }
  
    75% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -moz-transform: translateY(0px);
      -ms-transform: translateY(0px);
    }
  
    100% {
      -webkit-transform: translateY(10);
              transform: translateY(10);
      -moz-transform: translateY(10);
      -ms-transform: translateY(10);
    }
  }
  
  #nariz {
    -webkit-transform: translateY(-9px);
            transform: translateY(-9px);
    -ms-transform: translateY(-9px);
    -moz-transform: translateY(-9px);
    -webkit-animation: nariz 5s linear infinite;
            animation: nariz 5s linear infinite;
  }
  
  @-webkit-keyframes nariz {
    0% {
      -webkit-transform: translateY(-10);
              transform: translateY(-10);
      -ms-transform: translateY(-10);
      -moz-transform: translateY(-10);
    }
  
    25% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -ms-transform: translateY(0px);
      -moz-transform: translateY(0px);
    }
  
    75% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -moz-transform: translateY(0px);
      -ms-transform: translateY(0px);
    }
  
    100% {
      -webkit-transform: translateY(10);
              transform: translateY(10);
      -moz-transform: translateY(10);
      -ms-transform: translateY(10);
    }
  }
  
  @keyframes nariz {
    0% {
      -webkit-transform: translateY(-10);
              transform: translateY(-10);
      -ms-transform: translateY(-10);
      -moz-transform: translateY(-10);
    }
  
    25% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -ms-transform: translateY(0px);
      -moz-transform: translateY(0px);
    }
  
    75% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -moz-transform: translateY(0px);
      -ms-transform: translateY(0px);
    }
  
    100% {
      -webkit-transform: translateY(10);
              transform: translateY(10);
      -moz-transform: translateY(10);
      -ms-transform: translateY(10);
    }
  }
  
  #boca {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -moz-transform: translateY(-5px);
    -webkit-animation: boca 5s linear infinite;
            animation: boca 5s linear infinite;
  }
  
  @-webkit-keyframes boca {
    0% {
      -webkit-transform: translateY(-10);
              transform: translateY(-10);
      -ms-transform: translateY(-10);
      -moz-transform: translateY(-10);
    }
  
    25% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -ms-transform: translateY(0px);
      -moz-transform: translateY(0px);
    }
  
    75% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -moz-transform: translateY(0px);
      -ms-transform: translateY(0px);
    }
  
    100% {
      -webkit-transform: translateY(10);
              transform: translateY(10);
      -moz-transform: translateY(10);
      -ms-transform: translateY(10);
    }
  }
  
  @keyframes boca {
    0% {
      -webkit-transform: translateY(-10);
              transform: translateY(-10);
      -ms-transform: translateY(-10);
      -moz-transform: translateY(-10);
    }
  
    25% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -ms-transform: translateY(0px);
      -moz-transform: translateY(0px);
    }
  
    75% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -moz-transform: translateY(0px);
      -ms-transform: translateY(0px);
    }
  
    100% {
      -webkit-transform: translateY(10);
              transform: translateY(10);
      -moz-transform: translateY(10);
      -ms-transform: translateY(10);
    }
  }
  
  #olhos {
    -webkit-transform: translateX(-2px);
            transform: translateX(-2px);
    -moz-transform: translateX(-2px);
    -ms-transform: translateX(-2px);
    -webkit-animation: olhos 2s linear infinite;
            animation: olhos 2s linear infinite;
  }
  
  @-webkit-keyframes olhos {
    0% {
      -webkit-transform: translateX(-2);
              transform: translateX(-2);
      -ms-transform: translateX(-2);
      -moz-transform: translateX(-2);
    }
  
    25% {
      -webkit-transform: translateX(2px);
              transform: translateX(2px);
      -moz-transform: translateX(2px);
      -ms-transform: translateX(2px);
    }
  
    75% {
      -webkit-transform: translateX(-2px);
              transform: translateX(-2px);
      -ms-transform: translateX(-2px);
      -moz-transform: translateX(-2px);
    }
  
    100% {
      -webkit-transform: translateX(2);
              transform: translateX(2);
      -ms-transform: translateX(2);
      -moz-transform: translateX(2);
    }
  }
  
  @keyframes olhos {
    0% {
      -webkit-transform: translateX(-2);
              transform: translateX(-2);
      -ms-transform: translateX(-2);
      -moz-transform: translateX(-2);
    }
  
    25% {
      -webkit-transform: translateX(2px);
              transform: translateX(2px);
      -moz-transform: translateX(2px);
      -ms-transform: translateX(2px);
    }
  
    75% {
      -webkit-transform: translateX(-2px);
              transform: translateX(-2px);
      -ms-transform: translateX(-2px);
      -moz-transform: translateX(-2px);
    }
  
    100% {
      -webkit-transform: translateX(2);
              transform: translateX(2);
      -ms-transform: translateX(2);
      -moz-transform: translateX(2);
    }
  }
  
  #notebook {
    -webkit-transform: translateY(-4px);
            transform: translateY(-4px);
    -moz-transform: translateY(-4px);
    -ms-transform: translateY(-4px);
    -webkit-animation: notebook 6s linear infinite;
            animation: notebook 6s linear infinite;
  }
  
  @-webkit-keyframes notebook {
    0% {
      -webkit-transform: translateY(-4);
              transform: translateY(-4);
    }
  
    25% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
    }
  
    75% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
    }
  
    100% {
      -webkit-transform: translateY(4);
              transform: translateY(4);
    }
  }
  
  @keyframes notebook {
    0% {
      -webkit-transform: translateY(-4);
              transform: translateY(-4);
    }
  
    25% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
    }
  
    75% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
    }
  
    100% {
      -webkit-transform: translateY(4);
              transform: translateY(4);
    }
  }
  
  #pernas {
    -webkit-transform: translateY(-9px);
            transform: translateY(-9px);
    -webkit-animation: pernas 6s infinite;
            animation: pernas 6s infinite;
  }
  
  @-webkit-keyframes pernas {
    0% {
      -webkit-transform: translateY(-10);
              transform: translateY(-10);
      -ms-transform: translateY(-10);
      -moz-transform: translateY(-10);
    }
  
    25% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -ms-transform: translateY(0px);
      -moz-transform: translateY(0px);
    }
  
    75% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -moz-transform: translateY(0px);
      -ms-transform: translateY(0px);
    }
  
    100% {
      -webkit-transform: translateY(10);
              transform: translateY(10);
      -moz-transform: translateY(10);
      -ms-transform: translateY(10);
    }
  }
  
  @keyframes pernas {
    0% {
      -webkit-transform: translateY(-10);
              transform: translateY(-10);
      -ms-transform: translateY(-10);
      -moz-transform: translateY(-10);
    }
  
    25% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -ms-transform: translateY(0px);
      -moz-transform: translateY(0px);
    }
  
    75% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -moz-transform: translateY(0px);
      -ms-transform: translateY(0px);
    }
  
    100% {
      -webkit-transform: translateY(10);
              transform: translateY(10);
      -moz-transform: translateY(10);
      -ms-transform: translateY(10);
    }
  }
  
  #pe {
    -webkit-animation: pe 6s infinite;
            animation: pe 6s infinite;
  }
  
  @-webkit-keyframes pe {
    0% {
      -webkit-transform: rotateX(2deg);
              transform: rotateX(2deg);
      -ms-transform: rotateX(2deg);
      -moz-transform: rotateX(2deg);
    }
  
    25% {
    }
  
    75% {
    }
  
    100% {
    }
  }
  
  @keyframes pe {
    0% {
      -webkit-transform: rotateX(2deg);
              transform: rotateX(2deg);
      -ms-transform: rotateX(2deg);
      -moz-transform: rotateX(2deg);
    }
  
    25% {
    }
  
    75% {
    }
  
    100% {
    }
  }
  
  #camisas {
    -webkit-transform: translateY(-9px);
            transform: translateY(-9px);
    -ms-transform: translateY(-9px);
    -moz-transform: translateY(-9px);
    -webkit-animation: camisas 6s infinite;
            animation: camisas 6s infinite;
  }
  
  @-webkit-keyframes camisas {
    0% {
      -webkit-transform: translateY(-10);
              transform: translateY(-10);
      -ms-transform: translateY(-10);
      -moz-transform: translateY(-10);
    }
  
    25% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -ms-transform: translateY(0px);
      -moz-transform: translateY(0px);
    }
  
    75% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -moz-transform: translateY(0px);
      -ms-transform: translateY(0px);
    }
  
    100% {
      -webkit-transform: translateY(10);
              transform: translateY(10);
      -moz-transform: translateY(10);
      -ms-transform: translateY(10);
    }
  }
  
  @keyframes camisas {
    0% {
      -webkit-transform: translateY(-10);
              transform: translateY(-10);
      -ms-transform: translateY(-10);
      -moz-transform: translateY(-10);
    }
  
    25% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -ms-transform: translateY(0px);
      -moz-transform: translateY(0px);
    }
  
    75% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -moz-transform: translateY(0px);
      -ms-transform: translateY(0px);
    }
  
    100% {
      -webkit-transform: translateY(10);
              transform: translateY(10);
      -moz-transform: translateY(10);
      -ms-transform: translateY(10);
    }
  }
  
  #camisacostas {
    -webkit-transform: translateY(-9px);
            transform: translateY(-9px);
    -ms-transform: translateY(-9px);
    -moz-transform: translateY(-9px);
    -webkit-animation: camisacostas 6s infinite;
            animation: camisacostas 6s infinite;
  }
  
  @-webkit-keyframes camisacostas {
    0% {
      -webkit-transform: translateY(-10);
              transform: translateY(-10);
      -ms-transform: translateY(-10);
      -moz-transform: translateY(-10);
    }
  
    25% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -ms-transform: translateY(0px);
      -moz-transform: translateY(0px);
    }
  
    75% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -moz-transform: translateY(0px);
      -ms-transform: translateY(0px);
    }
  
    100% {
      -webkit-transform: translateY(10);
              transform: translateY(10);
      -moz-transform: translateY(10);
      -ms-transform: translateY(10);
    }
  }
  
  @keyframes camisacostas {
    0% {
      -webkit-transform: translateY(-10);
              transform: translateY(-10);
      -ms-transform: translateY(-10);
      -moz-transform: translateY(-10);
    }
  
    25% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -ms-transform: translateY(0px);
      -moz-transform: translateY(0px);
    }
  
    75% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -moz-transform: translateY(0px);
      -ms-transform: translateY(0px);
    }
  
    100% {
      -webkit-transform: translateY(10);
              transform: translateY(10);
      -moz-transform: translateY(10);
      -ms-transform: translateY(10);
    }
  }
  
  #cabeca {
    -webkit-transform: translateY(-9px);
            transform: translateY(-9px);
    -ms-transform: translateY(-9px);
    -moz-transform: translateY(-9px);
    -webkit-animation: cabeca 6s infinite;
            animation: cabeca 6s infinite;
  }
  
  @-webkit-keyframes cabeca {
    0% {
      -webkit-transform: translateY(-8);
              transform: translateY(-8);
    }
  
    25% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
    }
  
    75% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
    }
  
    100% {
      -webkit-transform: translateY(8);
              transform: translateY(8);
    }
  }
  
  @keyframes cabeca {
    0% {
      -webkit-transform: translateY(-8);
              transform: translateY(-8);
    }
  
    25% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
    }
  
    75% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
    }
  
    100% {
      -webkit-transform: translateY(8);
              transform: translateY(8);
    }
  }
  
  #macae {
    opacity: 1;
    -webkit-animation: macae 2s linear;
            animation: macae 2s linear;
  }
  
  @-webkit-keyframes macae {
    0% {
      opacity: 0.2;
    }
  
    25% {
    }
  
    75% {
    }
  
    100% {
    }
  }
  
  @keyframes macae {
    0% {
      opacity: 0.2;
    }
  
    25% {
    }
  
    75% {
    }
  
    100% {
    }
  }
  
  #luzmacae {
    -webkit-animation: luzmacae 5s;
            animation: luzmacae 5s;
  }
  
  @-webkit-keyframes luzmacae {
    0% {
      opacity: 0.2;
    }
  
    25% {
      opacity: 1;
    }
  
    75% {
      opacity: 1;
    }
  
    100% {
      opacity: 0.2;
    }
  }
  
  @keyframes luzmacae {
    0% {
      opacity: 0.2;
    }
  
    25% {
      opacity: 1;
    }
  
    75% {
      opacity: 1;
    }
  
    100% {
      opacity: 0.2;
    }
  }
  
  #orelhas {
    -webkit-animation: orelhas 6s linear infinite;
            animation: orelhas 6s linear infinite;
  }
  
  @-webkit-keyframes orelhas {
    0% {
      -webkit-transform: translateY(-10);
              transform: translateY(-10);
      -ms-transform: translateY(-10);
      -moz-transform: translateY(-10);
    }
  
    25% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -ms-transform: translateY(0px);
      -moz-transform: translateY(0px);
    }
  
    75% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -moz-transform: translateY(0px);
      -ms-transform: translateY(0px);
    }
  
    100% {
      -webkit-transform: translateY(10);
              transform: translateY(10);
      -moz-transform: translateY(10);
      -ms-transform: translateY(10);
    }
  }
  
  @keyframes orelhas {
    0% {
      -webkit-transform: translateY(-10);
              transform: translateY(-10);
      -ms-transform: translateY(-10);
      -moz-transform: translateY(-10);
    }
  
    25% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -ms-transform: translateY(0px);
      -moz-transform: translateY(0px);
    }
  
    75% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -moz-transform: translateY(0px);
      -ms-transform: translateY(0px);
    }
  
    100% {
      -webkit-transform: translateY(10);
              transform: translateY(10);
      -moz-transform: translateY(10);
      -ms-transform: translateY(10);
    }
  }
  
  #sombras {
    -webkit-animation: sombras 6.1s linear infinite;
            animation: sombras 6.1s linear infinite;
  }
  
  @-webkit-keyframes sombras {
    0% {
      -webkit-transform: scale(0.96);
              transform: scale(0.96);
    }
  
    25% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  
    75% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  
    100% {
      -webkit-transform: scale(0.96);
              transform: scale(0.96);
    }
  }
  
  @keyframes sombras {
    0% {
      -webkit-transform: scale(0.96);
              transform: scale(0.96);
    }
  
    25% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  
    75% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  
    100% {
      -webkit-transform: scale(0.96);
              transform: scale(0.96);
    }
  }